<template>
	<div class="dheader">
         <div class="center">

            <div class="back-btn" @click="back()">
              <LeftOutlined />
              <span class="text">{{title}}</span>
            </div>

             <div class="imgbox">
               <!-- <img class="logo" :src="require('../assets/logo-img.png')" /> -->
               <img class="logo" :src="require('../assets/logo-text.png')" />
             </div>
         </div>
      </div>
</template>

<script>
import {useRouter, useRoute} from 'vue-router'
import { LeftOutlined } from '@ant-design/icons-vue';

export default {
	name: 'DHeader',
  components:{LeftOutlined},
	props:{
		activeIdx:{type:Number,default:1},
    title:{type:String,default:"返回"}
	},
	setup(props,{emit}){
    const router = useRouter();
    // const route = useRoute();


    const back = ()=>{
      console.log(window.history);
      if(window.history.state.back!=null){
        router.back();
      }else{
        router.push('/');
      }
      
    }
 		return {back}
	}
}
</script>

<style scoped>
.dheader{height:64px; position: fixed; z-index:999; top:0; left:0; right:0; background: #fff; box-shadow: 0 1px 3px rgba(0,0,0,.3);}
.dheader .center{width:1100px; margin:auto; position:relative;}
.dheader .center .imgbox{text-align: left; padding-left:90px;}
.dheader .center .logo{height:30px; margin-top:17px;}

.back-btn{height:64px; line-height:64px; padding-left:15px; color:#333; position: absolute; top:0; left:0; cursor:pointer;}
.back-btn .anticon{font-weight: bold; margin-right:4px; font-size:20px; }
.back-btn .text{font-weight: bold; font-size:20px; }

@media screen and (max-width: 800px) {
  .dheader{height:44px;}
  .dheader .center{width:auto;}
  .dheader .center .imgbox{text-align:center; padding-left:0px;}
  .dheader .center .logo{height:25px;margin-top:10px;}

  .back-btn{height:44px; line-height:44px; padding-left:10px; color:#333; position: absolute; top:0; left:0;}
  .back-btn .anticon{font-weight: bold; margin-right:4px; font-size:18px; }
  .back-btn .text{font-weight: bold; font-size:18px; }
}
</style>