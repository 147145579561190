<template>
  <div class="detail">
  		<backHeader></backHeader>

      	<div class="wrap">
	        <div class="leftbox">
	             <div class="nav-box">
	               <leftNav title="企业概况" :list="navList"></leftNav>
	             </div>
	         </div>

	         <div class="rightbox">
	           <div class="intro">
	           		<router-view v-slot="{ Component }">
  				        <transition name="breadcrumb-transform" mode="out-in">
  				          <component :is="Component" :list="list" />
  				        </transition>
				        </router-view>    
	               <!-- <router-view :list="list"></router-view> -->
	           </div>

	        </div>
        </div>
      <!-- <back-top></back-top> -->
      <d-footer ></d-footer>
   </div>
</template>

<script>
import { ref,onMounted,watch,computed} from 'vue'
// import {useStore} from 'vuex'
import {useRouter, useRoute} from 'vue-router'

import backTop  from '@/components/backTop.vue'
import backHeader  from '@/components/backHeader.vue'
import DFooter from '../index/components/DFooter.vue'
import leftNav  from '@/components/leftNav.vue'
import service from '../../service.js';


import {useScrollToParamsId} from '@/use/diy-use.js'

export default {
  name: 'about',
  components:{backTop,DFooter,backHeader,leftNav},
  setup(){
      useScrollToParamsId();

      const navList = [
      	{title:"企业简介",path:"/about/ab1"},
      	{title:"科研团队",path:"/about/ab2"},
      	{title:"技术介绍",path:"/about/ab3"},
      	{title:"服务范围",path:"/about/ab4"}
      ];
      document.title="企业概况_粤增减材"

      const list = ref([]);
      let getList = async function(){
        const res =(await service({url:"/api/company?action=list",method: 'get'})).retdata.list; 
          console.log(res); 
          list.value = res;
          return list;
      }
      getList();

      const handleClick = (e)=>{
         e.preventDefault();
      }

      return {
         navList,
         list,
         handleClick
      }
  }
}
</script>

<style scoped>
.detail{background: #f1f1f1;  overflow: hidden; }

.wrap{width:1100px; margin:74px auto 20px auto; min-height: calc( 100vh - 216px ); position: relative; overflow: hidden;}

.leftbox{width:290px; position: fixed; }
.leftbox .nav-box{padding:20px 15px; background: #fff;}
.rightbox{margin-left:310px; background: #fff; padding:10px 25px 20px 25px; line-height: 2; min-height: calc(100vh - 216px)}


@media screen and (max-width:800px) {
.wrap{width:auto; margin:49px auto 10px auto; min-height: calc( 100vh - 176px );}
.leftbox .nav-box{padding:0; background: none;}
.rightbox{margin-left:0px; padding:10px 10px 20px 10px; min-height: calc(100vh - 176px)}
.rightbox .img-box img{width: 380px; margin-top:10px;}
}
</style>


<style type="text/css">
  .about-detail img{max-width: 740px;}
  .about-detail ul{list-style-type: disc;}
  @media screen and (max-width:800px) {
    .about-detail img{max-width: 394px; margin-top:10px;}
  }
</style>
